<template>
  <div class="py-5 container-fluid">
    <div class="row mt-4 justify-content-center">
      <div class="col-11">
        <div class="card">
          <div class="card-header pb-0 text-center my-3">
            <h4>
              Available <b>Spray Patterns</b> for powder
              <b
                ><i>{{ powder.name }}</i></b
              >
            </h4>
          </div>
          <div
            class="card-body pb-3 text-end"
            style="padding-top: 0.5rem"
          >
            <div class="d-flex flex-column">
              <div class="text-center">
                <button
                  class="h-120 btn bg-gradient-info ms-lg-auto me-lg-0 me-auto mt-lg-0 mb-0"
                  style="width: 300px; font-size: 0.91rem; height: 100px; background-color: rgb(241, 241, 241)"
                  @click="
                    $router.push('/applications/onsite/powdermodeladd/' + lineId + '/' + visitId + '/' + powderId)
                  "
                >
                  New Powder Model Calibration
                  <p
                    style="
                      font-size: 0.91rem;
                      line-height: 1rem;
                      padding-top: 5px;
                      padding-bottom: 0px;
                      margin-bottom: 0px;
                    "
                  >
                    (Estimated 10 mins)
                  </p>
                </button>
              </div>
              <div class="row">
                <PowderModelListOnSite
                  :line-name="line.name"
                  :line-id="lineId"
                  :visit-id="visitId"
                  :powder-id="powderId"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <stepper-line-visit
      :line-id="lineId"
      :visit="visit"
    />
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import PowderModelListOnSite from "./components/PowderModelListOnSite.vue";
import { useGetLine } from "@/views/composables.js";
import StepperLineVisit from "./components/StepperLineVisit.vue";
import { useGetVisit } from "./utils/utils";

export default {
  name: "PowderModelsLibraryOnSite",
  components: {
    PowderModelListOnSite,
    StepperLineVisit,
  },
  props: {
    lineId: {
      type: String,
      default: null,
    },
    visitId: {
      type: String,
      default: null,
    },
    powderId: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { line, getLine } = useGetLine();
    const { visit, getVisit } = useGetVisit();
    return { line, getLine, visit, getVisit };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    {
      return {
        showMenu: false,
        powder: {
          name: "",
          powder_manufacturer: "",
          powder_id: "",
        },
        showAdvancedCalibrationButton: false,
      };
    }
  },
  computed: {},
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    this.getData();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async getData() {
      const null_powder = await this.checkNotNullPowder();
      if (!null_powder) {
        await Promise.all([this.getLine(this.lineId), this.getVisit(this.visitId), this.getPowder()]);
      }
    },
    async checkNotNullPowder() {
      if (this.powderId == "null") {
        await this.$swal({
            title: this.$t("Powder not selected"),
            text: "Please select a Powder in Benchmark stage",
            icon: "warning",
            confirmButtonText: "OK",
        })
        this.$router.push("/applications/onsite/benchmarkandtarget/" + this.lineId + "/" + this.visitId);
        return true;
      }
      return false;
    },
    async getPowder() {
      try {
        const response = await axios.get("/api/v1/onsite/powder/" + this.powderId);
        this.powder = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<i18n>
  {
    "EN": {
    },
    "DE": {
    },
    "ES": {
    },
  }
</i18n>
