<template>
  <div class="py-5 container-fluid">
    <div id="topinfo">
      <div class="row mt-4 justify-content-center">
        <div class="card">
          <div class="card-header mb-4 text-center">
            <h4 class="font-weight-bolder">Guns Equalization</h4>
          </div>
          <div class="card-body" style="padding-top: 0.5rem">
            <div class="row">
              <div class="row mb-3">
                <h5>Powder Amount Parameters</h5>
                <div class="row mb-3">
                  <div class="col-3">
                    <label> Min </label>
                    <input
                      v-model="minPowderOutput"
                      type="number"
                      class="form-control my-2"
                      placeholder="MIN"
                      @keydown="numericOnly"
                      @change="saveFormProgress"
                    />
                  </div>
                  <div class="col-3">
                    <label> Max </label>
                    <input
                      v-model="maxPowderOutput"
                      type="number"
                      class="form-control my-2"
                      placeholder="MAX"
                      @keydown="numericOnly"
                      @change="saveFormProgress"
                    />
                  </div>
                  <div class="col-3">
                    <label class="mt-2 mb-2"> Time Interval [s]</label>
                    <input
                      v-model="powderOutputTimeInterval"
                      type="number"
                      min="15"
                      class="form-control my-2"
                      placeholder="TIME INTERVAL"
                      @keydown="numericOnly"
                      @change="saveFormProgress"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <h5 class="text-start">Powder Output Measurements</h5>
              <div style="overflow-x: auto">
                <div class="table-responsive">
                  <table class="table table-sm text-start" style="border-width: 0 !important">
                    <thead>
                      <tr>
                        <th>Gun</th>
                        <th v-for="(pair, index) in maxPairs" :key="'header-' + index">
                          Setting {{ index + 1 }} Weight {{ index + 1 }}
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(gun, gunIndex) in powderoutput_measurements" :key="gunIndex">
                        <td>{{ gunIndex + 1 }}</td>
                        <td v-for="(pair, pairIndex) in maxPairs" :key="'pair-' + gunIndex + '-' + pairIndex">
                          <div v-if="gun.gun_measurements[pairIndex]" class="d-flex">
                            <input
                              v-model="gun.gun_measurements[pairIndex].setting"
                              type="number"
                              :class="`form-control ${
                                gun.gun_measurements[pairIndex].setting != '' &&
                                (gun.gun_measurements[pairIndex].setting < minPowderOutput ||
                                  gun.gun_measurements[pairIndex].setting > maxPowderOutput)
                                  ? 'is-invalid'
                                  : ''
                              } form-control-sm me-1`"
                              placeholder="Setting"
                              style="width: 60px"
                              @change="saveFormProgress"
                              @blur="PowderAmountSettingInputRangeCheck($event.target.value)"
                            />
                            <input
                              v-model="gun.gun_measurements[pairIndex].weight"
                              type="number"
                              class="form-control form-control-sm me-1"
                              placeholder="Weight"
                              style="width: 60px"
                              @change="saveFormProgress"
                            />
                          </div>
                        </td>
                        <td>
                          <div class="d-flex">
                            <button class="btn btn-success" @click="addPair(gunIndex)">Add</button>
                            <div class="col-1"></div>
                            <button
                              v-if="gun.gun_measurements.length > 2"
                              class="btn btn-danger"
                              @click="deletePair(gunIndex)"
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                      <td :colspan="maxPairs.length + 2">
                        <div class="text-center mt-0 mb-0">
                          <button class="btn btn-primary" @click="showPowderOutputGraph">
                            Show Powder Output Chart
                          </button>
                        </div>
                      </td>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-if="!emptyPowderAmountMeasurements && showPowderThroughputData" class="row">
              <div class="col-lg-7">
                <h5 class="">Powder Output Chart</h5>
                <powder-output-chart />
              </div>
              <div class="col-lg-5">
                <div class="card text-bolder mt-6 text-center">
                  Statistics of current throughput per gun:
                  <p class="mt-4">
                    Min throughput: {{ original_powder_throughput_statistics.min_value }} [g/min]
                  </p>
                  <p>Max throughput: {{ original_powder_throughput_statistics.max_value }} [g/min]</p>
                  <p>
                    Max throughput difference: {{ original_powder_throughput_statistics.diff_value }} [g/min]
                  </p>
                  <p>
                    Throughput std dev: {{ original_powder_throughput_statistics.std_value }} [g/min]
                  </p>
                </div>
              </div>
            </div>
            <div v-if="emptyBenchmarkPowderAmounts">
              To see equalized Powder Amount settings, first provide Powder Amount settings for each gun at Benchmark
              Stage.
            </div>
            <div v-if="!emptyBenchmarkPowderAmounts && emptyPowderAmountMeasurements">
              To see equalized Powder Amount settings, first provide Powder Output Measurements above.
            </div>
            <div v-if="!emptyBenchmarkPowderAmounts && !emptyPowderAmountMeasurements && showPowderThroughputData">
              <gun-throughput
                :gun-expected-output="gunExpectedOutput"
                :line="line"
                :powder-amount-parameters="powder_amount_parameters"
              />
              <div class="row mt-4">
                <div class="col-lg-6">
                  <h5>
                    Thickness Measurements [{{
                      this.$store.state.units_system[$store.state.user_data.unit_system].thickness
                    }}]
                  </h5>
                  <div class="table-responsive">
                    <table class="table table-sm text-center text-xs">
                      <thead>
                        <tr>
                          <th>Measurement</th>
                          <th>Thickness</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(_, index) in thickness_measurements" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td>
                            <input
                              v-model="thickness_measurements[index]"
                              type="number"
                              class="form-control text-center"
                              style="width: 50%; margin: 0 auto"
                              min="0"
                              @change="
                                checkLastRow();
                                saveFormProgress();
                              "
                              @keydown="numericOnly"
                            />
                          </td>
                        </tr>
                        <td colspan="2">
                          <div class="text-center mt-0 mb-0">
                            <button class="btn btn-primary" @click="showThicknessGraph()">
                              Show thickness distribution chart
                            </button>
                          </div>
                        </td>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-lg-6">
                  <gaussian-chart title="Thickness Distribution Chart" chart-name="gaussianPowderOutputChart" />
                  <thickness-measurement-statistic
                    :benchmark_thickness_measurements="benchmark_stage_data.thickness_measurements"
                    :powderoutput_thickness_measurements="thickness_measurements"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 text-center mt-4 mb-4">
              <button
                type="button"
                class="mt-4 mb-2 text-center btn"
                :class="save_button_green ? 'bg-gradient-success' : 'bg-gradient-secondary'"
                style="width: 90%"
                @click="changesSavedSwal"
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <stepper-line-visit :line-id="lineId" :visit="visit" />
      </div>
    </div>
  </div>
</template>

<script>
import StepperLineVisit from "./components/StepperLineVisit.vue";
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import {
  calculateMu,
  calculateSigma,
  gaussian,
  generateX,
  generateXforMultipleMuSigma,
  generateY,
  generateYwithXvalues,
} from "./utils/gaussianchart";

import GaussianChart from "./components/GaussianChart.vue";
import eventBus from "./utils/eventBus";
import {
  useGetVisit,
  useGetBenchmark,
  getThicknessMeasurementsMean,
  getThicknessMeasurementsStd,
  getStdDifferencePercentage,
  measurementsMax,
  measurementsMin,
  numericOnly,
  showStatistics,
} from "./utils/utils";
import PowderOutputChart from "./components/PowderOutputChart.vue";
import { useGetLine } from "@/views/composables.js";
import GunThroughput from "./components/GunThroughput.vue";
import ThicknessMeasurementStatistic from "./components/ThicknessMeasurementStatistic.vue";

export default {
  name: "PowderOutput",
  components: {
    StepperLineVisit,
    GaussianChart,
    PowderOutputChart,
    GunThroughput,
    ThicknessMeasurementStatistic,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
    visitId: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { line, getLine } = useGetLine();
    const { visit, getVisit } = useGetVisit();
    const { benchmark_stage_data, getBenchmarkData } = useGetBenchmark();
    return { visit, getVisit, benchmark_stage_data, getBenchmarkData, line, getLine };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;

    return {
      original_powder_throughput_statistics: {
        min_value: null,
        max_value: null,
        diff_value: null,
        std_value: null,
      },
      powderoutput_measurements: [
        {
          gun_measurements: [
            {
              setting: "",
              weight: "",
            },
            {
              setting: "",
              weight: "",
            },
          ],
        },
      ],
      showPowderThroughputData: false,
      showThicknessMeasurementStatistic: false,
      minPowderOutput: null,
      maxPowderOutput: null,
      powderOutputTimeInterval: 60,
      thickness_measurements: [null, null, null, null, null],
      save_button_green: true,
      received_powderoutput_data: {
        min_powder_output: null,
        max_powder_output: null,
        powderoutput_measurements: null,
        thickness_measurements: Array(5).fill(null),
      },

      max_metric_decimals: 0,
      max_imperial_decimals: 2,

      // Powder Amount Calibration variables
      powder_amount_calibrations: [],
      powder_amount_calibration_measures: null,
      reference_gun_index: null,
      selected_guns_for_powder_amount: [],
      expected_powder_per_minute: null,
      powder_amount_parameters: [],
      selected_powder_amount_calibration: null,
      dropdownVisible: false,
      searchQuery: "",
    };
  },
  computed: {
    gunExpectedOutput: {
      get() {
        if (this.expected_powder_per_minute == null || this.expected_powder_per_minute == "") {
          return 0;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.expected_powder_per_minute * this.$store.state.conversion_factors.g_to_oz).toFixed(2),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.expected_powder_per_minute.toFixed(0));
        } else {
          return this.expected_powder_per_minute;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.expected_powder_per_minute = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.expected_powder_per_minute = value / this.$store.state.conversion_factors.g_to_oz;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.expected_powder_per_minute = value;
          }
        }
      },
    },
    maxPairs() {
      let maxLength = 0;
      this.powderoutput_measurements.forEach(gun => {
        if (gun.gun_measurements.length > maxLength) {
          maxLength = gun.gun_measurements.length;
        }
      });
      return Array.from({ length: maxLength });
    },
    emptyBenchmarkPowderAmounts() {
      if (this.benchmark_stage_data.gun_settings == null) {
        return true;
      }
      const powderAmountSettings = this.benchmark_stage_data.gun_settings.map(item => item.powder);
      const emptyPowderAmounts = powderAmountSettings.some(
        powder_amount_setting => powder_amount_setting == "" || powder_amount_setting == null,
      );
      return emptyPowderAmounts;
    },
    emptyPowderAmountMeasurements() {
      const empty_powder_amount_measurements = this.powderoutput_measurements.some(gun =>
        gun.gun_measurements.some(pair => pair.setting == "" || pair.weight == ""),
      );
      return empty_powder_amount_measurements;
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
    this.getData();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    generateX,
    gaussian,
    generateY,
    calculateMu,
    calculateSigma,
    generateXforMultipleMuSigma,
    generateYwithXvalues,
    getThicknessMeasurementsMean,
    getThicknessMeasurementsStd,
    getStdDifferencePercentage,
    measurementsMin,
    measurementsMax,
    showStatistics,
    numericOnly,
    async getData() {
      await this.getVisit(this.visitId);
      await this.getLine(this.lineId);
      await this.getBenchmarkData(this.visitId, this.line.total_pistols[0]);

      this.benchmark_stage_data.total_pistols = this.line.total_pistols[0];
      this.powderoutput_measurements = this.generateGuns(this.benchmark_stage_data.total_pistols);

      await this.getPowderOutputData();
      await this.getPowderAmountCalibrationStatistic();
      await this.showPowderOutputGraph();

      if (this.thickness_measurements.some(m => m !== null && m !== "" && m !== 0)) {
        this.showThicknessGraph();
      }
    },
    generateGuns(total_guns) {
      return Array.from({ length: total_guns }, () => ({
        gun_measurements: [
          {
            setting: "",
            weight: "",
          },
          {
            setting: "",
            weight: "",
          },
        ],
      }));
    },
    toggleDropdown() {
      if (this.powder_amount_calibrations.length == 1) {
        return;
      }
      this.dropdownVisible = !this.dropdownVisible;
    },
    generateMeasurementList(powderoutput_measurements) {
      const results = [];

      powderoutput_measurements.map(gun => {
        const validPairs = gun.gun_measurements.filter(pair => {
          return pair.setting !== "" && pair.weight !== "";
        });

        const result = {
          powderAmount: validPairs.map(pair => parseFloat(pair.setting)),
          emptyBagWeight: Array(validPairs.length).fill(0),
          fullBagWeight: validPairs.map(pair => parseFloat(pair.weight)),
        };

        if (result.powderAmount.length > 0 || result.fullBagWeight.length > 0) {
          results.push(result);
        }
      });

      return results;
    },
    async getPowderOutputChartDataset() {
      let body = {
        min_powder_output: this.minPowderOutput,
        max_powder_output: this.maxPowderOutput,
        time_interval: this.powderOutputTimeInterval,
        powderoutput_measurements: JSON.stringify(this.powderoutput_measurements),
      };

      let response = await axios.post("/api/v1/onsite/powderoutputchart/", body);

      this.$store.state.powderOutputChart = response.data.powderoutput_measurements;
    },
    async getPowderAmountCalibrationStatistic() {
      if (this.emptyBenchmarkPowderAmounts || this.emptyPowderAmountMeasurements) {
        return;
      }

      try {
        let body = {
          time_interval: this.powderOutputTimeInterval,
          measures_list: this.generateMeasurementList(this.powderoutput_measurements),
          powder_amounts: this.visit.benchmarkstage.gun_settings.map(setting => parseFloat(setting.powder)),
        };

        const response = await axios.post("/api/v1/fp/computepowderamountcalibrationstatistic/", body);

        this.original_powder_throughput_statistics = response.data;
        this.expected_powder_per_minute = this.original_powder_throughput_statistics.mean_powder_throughput;
        this.saveFormProgress();

        this.powder_amount_calibrations = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async computePowderAmountParameterResults() {
      if (this.emptyBenchmarkPowderAmounts || this.emptyPowderAmountMeasurements) {
        return;
      }
      try {
        let body = {
          line: this.lineId,
          time_interval: this.powderOutputTimeInterval,
          measures_list: this.generateMeasurementList(this.powderoutput_measurements),
          powder_per_minute: this.expected_powder_per_minute,
          gun_idx: null,
          powder_amount_param: null,
        };

        let response = await axios.post("/api/v1/fp/computepowderamountcalibration/", body);
        this.powder_amount_parameters = response.data.powder_amount_params;
        this.roundPowderAmountParameters();
      } catch (error) {
        console.error(error);
      }
    },
    roundPowderAmountParameters() {
      this.powder_amount_parameters = this.powder_amount_parameters.map(param => {
        return parseFloat(param.toFixed(1));
      });
    },
    clearPowderAmountParameters() {
      this.powder_amount_parameters = Array.from({ length: this.benchmark_stage_data.total_pistols }, () => null);
      this.expected_powder_per_minute = null;
    },
    checkPowderThroughputAlert() {
      if (this.expected_powder_per_minute <= 0) {
        this.$swal({
          title: "Invalid Expected Throughput",
          text: "The expected throughput must be greater than 0.",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.clearPowderAmountParameters();
      }
    },
    checkPowderAmountResultsRangeAlert() {
      let calibration_out_of_range_popup_shown = false;
      for (let index = 0; index < this.powder_amount_parameters.length; index++) {
        if (
          this.powder_amount_parameters[index] !== null &&
          this.powder_amount_parameters[index] !== "" &&
          (this.powder_amount_parameters[index] < this.minPowderOutput ||
            this.powder_amount_parameters[index] > this.maxPowderOutput)
        ) {
          if (!calibration_out_of_range_popup_shown) {
            this.$swal({
              title: "Calibration Out of Range",
              text: `${"The resulting Powder Amount setting is out of range for Gun"} ${index + 1}.\n Min: ${
                this.minPowderOutput
              }, Max: ${this.maxPowderOutput}.
              ${"Obtained Parameter"}: ${this.powder_amount_parameters[index]}`,
              icon: "error",
              confirmButtonText: "OK",
            });
            calibration_out_of_range_popup_shown = true;
          }
        }
      }
    },
    async getPowderOutputData() {
      try {
        let response = await axios.get("/api/v1/onsite/powderoutput/" + this.visitId);

        if (response.data == null || response.data == undefined || response.status !== 200) {
          return;
        }

        this.received_powderoutput_data.min_powder_output = response.data.min_powder_output;
        this.received_powderoutput_data.max_powder_output = response.data.max_powder_output;

        this.received_powderoutput_data.powderoutput_measurements = response.data.powder_amount_measurements;
        this.received_powderoutput_data.thickness_measurements = response.data.thickness_measurements;

        this.minPowderOutput = response.data.min_powder_output;
        this.maxPowderOutput = response.data.max_powder_output;

        if (response.data.time_interval !== null) {
          this.powderOutputTimeInterval = response.data.time_interval;
        }

        if (response.data.powder_amount_measurements !== null) {
          this.powderoutput_measurements = response.data.powder_amount_measurements;
        }
        if (response.data.thickness_measurements !== null) {
          this.thickness_measurements = response.data.thickness_measurements;
          this.checkLastRow();
        }

        this.benchmark_stage_data = JSON.parse(JSON.stringify(this.benchmark_stage_data));

        if (response.data.powder_amount_measurements !== null && this.isPowderOutputMeasurementsFilled()) {
          await this.showPowderOutputGraph();
        }
      } catch (error) {
        console.error(error);
      }
      this.$store.state.isLoaded = false;
    },
    isPowderOutputMeasurementsFilled() {
      let total_empty_measures = this.powderoutput_measurements
        .map(powderoutput_measurement => powderoutput_measurement.gun_measurements)
        .filter(gun_measurment =>
          gun_measurment.some(
            gun_measurment =>
              gun_measurment.setting == "" ||
              gun_measurment.setting == null ||
              gun_measurment.weight == "" ||
              gun_measurment.weight == null,
          ),
        ).length;

      let is_powder_output_measurements_filled = total_empty_measures == 0;

      return is_powder_output_measurements_filled;
    },
    getFormProgressStatus() {
      const PROGRESS_STATUS_PENDING = "Pending";
      const PROGRESS_STATUS_IN_PROGRESS = "In Progress";
      const PROGRESS_STATUS_COMPLETED = "Completed";

      if (
        this.powderoutput_measurements
          .map(gun => gun.gun_measurements)
          .filter(gun_measurment =>
            gun_measurment.some(gun_measurment => gun_measurment.setting !== "" && gun_measurment.weight !== ""),
          ) &&
        this.thickness_measurements.filter(m => m !== null && m !== "") &&
        this.expected_powder_per_minute !== null
      ) {
        return PROGRESS_STATUS_COMPLETED;
      } else if (
        this.powderoutput_measurements
          .map(gun => gun.gun_measurements)
          .filter(gun_measurment =>
            gun_measurment.some(gun_measurment => gun_measurment.setting === "" && gun_measurment.weight === ""),
          ) ||
        this.thickness_measurements.filter(m => m === null && m === "") ||
        this.expected_powder_per_minute === null
      ) {
        return PROGRESS_STATUS_IN_PROGRESS;
      } else {
        return PROGRESS_STATUS_PENDING;
      }
    },
    saveFormProgress() {
      this.save_button_green = true;
      const nonEmptyThicknessMeasurements = this.thickness_measurements.filter(m => m !== null && m !== "");
      const formData = {
        powder_amount_measurements: this.powderoutput_measurements,
        thickness_measurements: nonEmptyThicknessMeasurements,
        min_powder_output: this.minPowderOutput ? this.minPowderOutput : null,
        max_powder_output: this.maxPowderOutput ? this.maxPowderOutput : null,
        time_interval: this.powderOutputTimeInterval,
        desired_output: this.expected_powder_per_minute,
        progress_status: this.getFormProgressStatus(),
      };

      try {
        axios.patch("/api/v1/onsite/powderoutput/" + this.visitId + "/", formData);
      } catch (error) {
        console.error(error);
      }
    },
    PowderAmountSettingInputRangeCheck(target_value) {
      if (
        this.powderoutput_measurements.some(gun =>
          gun.gun_measurements.some(pair => pair.setting !== "" && pair.setting < this.minPowderOutput),
        )
      ) {
        this.$swal({
          title: `Invalid Powder Output: ${target_value}<br>Value out of range.`,
          text: `Powder Output must be greater than the minimum allowed of ${this.minPowderOutput}.`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => this.removePowderMeasurementInput(target_value));
        return;
      } else if (
        this.powderoutput_measurements.some(gun =>
          gun.gun_measurements.some(pair => pair.setting !== "" && pair.setting > this.maxPowderOutput),
        )
      ) {
        this.$swal({
          title: `Invalid Powder Output: ${target_value}<br>Value out of range.`,
          text: `Powder Output must be smaller than the maximum allowed of ${this.maxPowderOutput}.`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => this.removePowderMeasurementInput(target_value));

        return;
      }
    },
    removePowderMeasurementInput(target_value) {
      this.powderoutput_measurements.map(gun =>
        gun.gun_measurements.map(pair => {
          if (pair.setting == target_value) {
            pair.setting = "";
          }
        }),
      );
    },
    async changesSavedSwal() {
      try {
        this.save_button_green = false;
        await Promise.all([
          this.getVisit(this.visitId),
          this.getBenchmarkData(this.visitId, this.line.total_pistols),
          this.getPowderOutputData(),
          this.getPowderAmountCalibrationStatistic(),
        ]);

        await this.computePowderAmountParameterResults();

        await this.$swal({
          title: "Changes saved",
          text: "Powder Output was saved successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error(error);
      }
    },
    checkLastRow() {
      while (this.thickness_measurements.length < 5) {
        this.thickness_measurements.push("");
      }

      if (this.thickness_measurements.every(m => m !== null && m !== "" && m !== 0)) {
        this.thickness_measurements.push("");
      } else if (
        (this.thickness_measurements[this.thickness_measurements.length - 1] == null ||
          this.thickness_measurements[this.thickness_measurements.length - 1] == "") &&
        (this.thickness_measurements[this.thickness_measurements.length - 2] == null ||
          this.thickness_measurements[this.thickness_measurements.length - 2] == "") &&
        this.thickness_measurements.length > 5
      ) {
        this.thickness_measurements.pop();
      }
    },
    thicknessMeasurementsUpdateChartData() {
      const mu = this.calculateMu(this.thickness_measurements);
      const sigma = this.calculateSigma(this.thickness_measurements, mu);

      this.$store.state.gaussianPowderOutputChart.labels = this.generateX(mu, sigma);

      const Xarray = this.$store.state.gaussianPowderOutputChart.labels.map(num => parseFloat(num));

      this.$store.state.minTargetThickness = Xarray.findIndex(
        num => num > this.benchmark_stage_data.minimum_target_thickness,
      );
      this.$store.state.maxTargetThickness = Xarray.findIndex(
        num => num > this.benchmark_stage_data.maximum_target_thickness,
      );

      this.$store.state.gaussianPowderOutputChart.datasets[0] = {
        label: "Guns Equalization",
        data: this.generateY(sigma, mu),
      };

      if (this.benchmark_stage_data.thickness_measurements !== null) {
        const benchmarkMu = this.calculateMu(this.benchmark_stage_data.thickness_measurements);
        const benchmarkSigma = this.calculateSigma(this.benchmark_stage_data.thickness_measurements, benchmarkMu);

        this.$store.state.gaussianPowderOutputChart.labels = this.generateXforMultipleMuSigma([
          [benchmarkMu, benchmarkSigma],
          [mu, sigma],
        ]);
        const Xarray = this.$store.state.gaussianPowderOutputChart.labels.map(num => parseFloat(num));

        this.$store.state.minTargetThickness = Xarray.findIndex(
          num => num > this.benchmark_stage_data.minimum_target_thickness,
        );
        this.$store.state.maxTargetThickness = Xarray.findIndex(
          num => num > this.benchmark_stage_data.maximum_target_thickness,
        );

        this.$store.state.gaussianPowderOutputChart.datasets[0] = {
          label: "Initial Benchmark",
          data: this.generateYwithXvalues(
            benchmarkSigma,
            benchmarkMu,
            this.$store.state.gaussianPowderOutputChart.labels,
          ),
        };

        this.$store.state.gaussianPowderOutputChart.datasets[1] = {
          label: "Guns Equalization",
          data: this.generateYwithXvalues(sigma, mu, this.$store.state.gaussianPowderOutputChart.labels),
        };
      }
    },
    addPair(gunIndex) {
      this.powderoutput_measurements[gunIndex].gun_measurements.push({ setting: "", weight: "" });
    },
    deletePair(gunIndex) {
      this.powderoutput_measurements[gunIndex].gun_measurements.pop();
    },
    async showPowderOutputGraph() {
      if (this.isPowderOutputMeasurementsFilled()) {
        this.showPowderThroughputData = true;
        await this.getPowderOutputChartDataset();

        await this.getPowderAmountCalibrationStatistic();
        await this.computePowderAmountParameterResults();
        eventBus.emit("draw-powderoutput-chart");
      }
    },
    showThicknessGraph() {
      this.thicknessMeasurementsUpdateChartData();
      this.showThicknessMeasurementStatistic = true;
      eventBus.emit("draw-gaussian-chart");
    },
  },
};
</script>
<style scoped>
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
  border-collapse: collapse; /* Ensure borders are collapsed */
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-border tbody tr:last-child td {
  border-width: 1px;
}

.container-fluid {
  padding-top: 20px;
}
</style>
