<template>
  <div
    class="card-body col-6 pt-0 p-3 mx-auto"
    style="cursor: pointer"
    @click="handleClick"
    :key="key"
    >
    <ul class="list-group">
      <li
        class="list-group-item border-0 d-flex p-3 mb-1 border-radius-lg"
        style="background-color: rgb(230, 230, 230); cursor: pointer;"
      >
        <div
          class="col-9 p-2"
          style="min-width: 100px"
        >
          <span class="text-lg">
            {{ $t("Line Name") }}
          </span>
          <div
            onmouseover="style.textDecoration = 'underline'"
            onmouseout="style.textDecoration = 'none'"
          >  
            <h6 class="text-2xl">{{ lineName }}</h6>
          </div>
        </div>
        <div class="col mt-2">
            <span class="text-xs">
            {{ $t("Created") }}
            </span>
          <div class="my-auto mb-0">
            <p style="font-size: 0.8rem">
              <b>{{ dateTime }}</b>
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LineCardOnSite",
  props: {
    key: {
      type: String,
      default: "",
    },
    lineName: {
      type: String,
      default: "",
    },
    lineId: {
      type: String,
      default: "",
    },
    dateTime: {
      type: String,
      default: "",
    },
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {};
  },
  methods: {
    handleClick() {
      if (this.$store.state.blueprint_light_license) {
       this.$router.push('/blueprintlight/powderequalization/' + this.lineId);
      } else {
        this.$router.push('/applications/onsite/linevisits/' + this.lineId);
      }
    },
  },
};
</script>
<i18n>
  {
    "EN": {
        "Line Name":"Line Name",
        "Created":"Created",
    },
    "DE": {
        "Line Name":"Linienname",
        "Created":"Erstellt",
    },
    "ES": {
        "Line Name":"Nombre de la Línea",
        "Created":"Creada",
    },
    "FR": {
        "Line Name":"Nom de la Ligne",
        "Created":"Créée",
    },
    "IT": {
        "Line Name":"Nome della Linea",
        "Created":"Creata",
    },
    "PL": {
        "Line Name":"Nazwa Linii",
        "Created":"Utworzony",
    },
    "TR": {
        "Line Name":"Hat İsmi",
        "Created":"Oluşturuldu",
    },
    "ZH": {
        "Line Name":"线路名称",
        "Created":"创建",
    },
  }
</i18n>
