import { createRouter, createWebHistory } from "vue-router";

import AdvancedPowderModelAdd from "../views/applications/flightpath/AdvancedPowderModelAdd.vue";
import BenchmarkAndTarget from "../views/applications/flightpathonsite/BenchmarkAndTarget.vue";
import Copilot from "../views/applications/copilot/Copilot.vue";
import Dashboard from "../views/dashboards/Dashboard.vue";
import PowderSaving from "../views/applications/flightpathonsite/PowderSaving.vue";
import FlightPath from "../views/applications/flightpath/FlightPath.vue";
import FlightPathOnSite from "../views/applications/flightpathonsite/FlightPathOnSite.vue";
import GenerateMonthlyUsageReport from "../views/applications/generatemonthlyusagereport/GenerateMonthlyUsageReport.vue";
import HealthCheck from "../views/applications/flightpathonsite/HealthCheck.vue";
import LineDetail from "../views/applications/flightpath/LineDetail.vue";
import LineVisits from "../views/applications/flightpathonsite/LineVisits.vue";
import NotFound from "../views/NotFound.vue";
import PublicOptimizationDetail from "../views/applications/flightpath/PublicOptimizationDetail.vue";
import OptimizationDetail from "../views/applications/flightpath/OptimizationDetail.vue";
import OptimizationsLibrary from "../views/applications/flightpath/OptimizationsLibrary.vue";
import PowderAmountCalibrationDetail from "../views/applications/flightpath/PowderAmountCalibrationDetail.vue";
import PowderAmountCalibrationsLibrary from "../views/applications/flightpath/PowderAmountCalibrationsLibrary.vue";
import PowderModelAdd from "../views/applications/flightpath/PowderModelAdd.vue";
import PowderModelAddOnSite from "../views/applications/flightpathonsite/PowderModelAddOnSite.vue";
import PowderModelDetail from "../views/applications/flightpath/PowderModelDetail.vue";
import PowderModelsLibrary from "../views/applications/flightpath/PowderModelsLibrary.vue";
import PowderModelsLibraryOnSite from "../views/applications/flightpathonsite/PowderModelsLibraryOnSite.vue";
import PowderOutput from "../views/applications/flightpathonsite/PowderOutput.vue";
import PowderSavingsCalculator from "../views/demo/powdersavingscalculator/PowderSavingsCalculator.vue";
import ReciprocatorOptimizationsLibrary from "../views/applications/flightpathonsite/ReciprocatorOptimizationsLibrary.vue";
import ReciprocatorOptimizationDetail from "../views/applications/flightpathonsite/ReciprocatorOptimizationDetail.vue";
import ResetPassword from "../views/auth/ResetPassword.vue";
import ResetPasswordByEmail from "../views/auth/ResetPasswordByEmail.vue";
import ResetPasswordConfirmation from "../views/auth/ResetPasswordConfirmation.vue";
import SignIn from "../views/auth/SignIn.vue";
import SprayPattern from "../views/applications/flightpathonsite/SprayPattern.vue";
import StaticLineOptimizationDetail from "../views/applications/flightpath/StaticLineOptimizationDetail.vue";
import StaticPowderModelAdd from "../views/applications/flightpath/StaticPowderModelAdd.vue";
import BlueprintLight from "../views/applications/flightpathonsite/blueprintlight/BlueprintLight.vue";
import PowderEqualization from "../views/applications/flightpathonsite/blueprintlight/PowderEqualization.vue";

import store from "../store";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: { name: "Dashboard" },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/applications/flightpath",
    name: "FlightPath",
    component: FlightPath,
  },
  {
    path: "/applications/copilot",
    name: "Copilot",
    component: Copilot,
  },
  {
    path: "/applications/onsite",
    name: "FlightPathOnSite",
    component: FlightPathOnSite,
  },
  {
    path: "/blueprintlight",
    name: "BlueprintLight",
    component: BlueprintLight,
  },
  {
    path: "/auth/sign-in",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/auth/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/auth/reset-password-by-email",
    name: "ResetPasswordByEmail",
    component: ResetPasswordByEmail,
  },
  {
    path: "/reset-password/confirm/:uid/:token/",
    name: "ResetPasswordConfirmation",
    component: ResetPasswordConfirmation,
    props: true,
  },
  {
    path: "/applications/flightpath/optimization/:lineId/:optimizationTypeProp?/:flightpathIdProp?",
    name: "OptimizationDetail",
    component: OptimizationDetail,
    props: true,
  },
  {
    path: "/applications/flightpath/optimization/public/:lineId/:optimizationTypeProp?/:flightpathIdProp?",
    name: "PublicOptimizationDetail",
    component: PublicOptimizationDetail,
    props: true,
  },
  {
    path: "/applications/onsite/optimization/:lineId/:visitId/:optimizationTypeProp?/:flightpathIdProp?",
    name: "ReciprocatorOptimizationDetail",
    component: ReciprocatorOptimizationDetail,
    props: true,
  },
  {
    path: "/applications/flightpath/optimizationslibrary/:lineId",
    name: "OptimizationsLibrary",
    component: OptimizationsLibrary,
    props: true,
  },
  {
    path: "/applications/flightpath/powdermodelslibrary/:lineId",
    name: "PowderModelsLibrary",
    component: PowderModelsLibrary,
    props: true,
  },
  {
    path: "/applications/flightpath/powdermodelslibrary/:powdermodelType/:powdermodelId",
    name: "PowderModelDetail",
    component: PowderModelDetail,
    props: true,
  },
  {
    path: "/applications/onsite/powdermodel/:powdermodelType/:powdermodelId",
    name: "PowderModelDetailOnSite",
    component: PowderModelDetail,
    props: true,
  },
  {
    path: "/applications/flightpath/powdermodelslibrary/advancedpowdermodeladd/:lineId",
    name: "AdvancedPowderModelAdd",
    component: AdvancedPowderModelAdd,
    props: true,
  },
  {
    path: "/applications/flightpath/powdermodelslibrary/powdermodeladd/:lineId",
    name: "PowderModelAdd",
    component: PowderModelAdd,
    props: true,
  },
  {
    path: "/applications/flightpath/powdermodelslibrary/staticpowdermodeladd/:lineId",
    name: "StaticPowderModelAdd",
    component: StaticPowderModelAdd,
    props: true,
  },
  {
    path: "/applications/line/:lineIdProp?",
    name: "LineDetail",
    component: LineDetail,
    props: true,
  },
  {
    path: "/applications/flightpath/staticlineoptimization/:lineId/:optimizationTypeProp?/:flightpathIdProp?",
    name: "StaticLineOptimizationDetail",
    component: StaticLineOptimizationDetail,
    props: true,
  },
  {
    path: "/applications/generatemonthlyusagereport/",
    name: "GenerateMonthlyUsageReport",
    component: GenerateMonthlyUsageReport,
  },
  {
    path: '/applications/flightpath/powderamountcalibrationslibrary/:lineId',
    name: 'PowderAmountCalibrationsLibrary',
    component: PowderAmountCalibrationsLibrary,
    props: true,
  },
  {
    path: '/applications/flightpath/powderamountcalibrationslibrary/powderamountcalibration/:lineId/:powderAmountCalibrationIdProp?',
    name: 'PowderAmountCalibrationDetail',
    component: PowderAmountCalibrationDetail,
    props: true,
  },
  {
    path: "/applications/onsite/linevisits/:lineId",
    name: "LineVisits",
    component: LineVisits,
    props: true,
  },
  {
    path: "/applications/onsite/benchmarkandtarget/:lineId/:visitId",
    name: "BenchmarkAndTarget",
    component: BenchmarkAndTarget,
    props: true,
  },
  {
    path: "/applications/onsite/healthcheck/:lineId/:visitId",
    name: "HealthCheck",
    component: HealthCheck,
    props: true,
  },
  {
    path: "/applications/onsite/powderoutput/:lineId/:visitId",
    name: "PowderOutput",
    component: PowderOutput,
    props: true,
  },
  {
    path: "/applications/onsite/spraypattern/:lineId/:visitId",
    name: "SprayPattern",
    component: SprayPattern,
    props: true,
  },
  {
    path: "/applications/onsite/reciprocatoroptimizationslibrary/:lineId/:visitId",
    name: "ReciprocatorOptimizationsLibrary",
    component: ReciprocatorOptimizationsLibrary,
    props: true,
  },
  {
    path: "/applications/onsite/PowderSavingonsite/:lineId/:visitId",
    name: "PowderSaving",
    component: PowderSaving,
    props: true,
  },
  {
    path: "/applications/onsite/powdermodels/:lineId/:visitId/:powderId",
    name: "PowderModelsLibraryOnSite",
    component: PowderModelsLibraryOnSite,
    props: true,
  },
  {
    path: "/applications/onsite/powdermodeladd/:lineId/:visitId/:powderId",
    name: "PowderModelAddOnSite",
    component: PowderModelAddOnSite,
    props: true,
  },
  {
    path: "/blueprintlight/powderequalization/:lineId/:powderEqualizationId?",
    name: "PowderEqualization",
    component: PowderEqualization,
    props: true,
  },
  {
    path: "/powdersavingscalculator/:calculatorTypeProp?",
    name: "PowderSavingsCalculator",
    component: PowderSavingsCalculator,
    props: true,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async to => {
  store.commit('setLoading', true);

  if (
    !store.state.user_data_loaded
    && to.name !== "SignIn"
    && to.name !== "ResetPasswordByEmail"
    && to.name !== "ResetPasswordConfirmation"
    && to.name !== "PublicOptimizationDetail"
  ) {
    await store.dispatch("getUserData");
  }

  if (
    // redirect to PowderSavingsCalculator without EXPO license
    (to.path == "/powdersavingscalculator/expo" || to.path == "/powdersavingscalculator/expo/")
    && (!store.state.isAuthenticated || !store.state.user_data.powder_savings_calculator_license)
  ) {
    store.commit('setLoading', false);
    return { name: "/powdersavingscalculator" };
  }

  if (
    // redirect to PowderSavingsCalculator EXPO version if user has expo license
    store.state.isAuthenticated
    && to.path == "/powdersavingscalculator/"
    && store.state.user_data.powder_savings_calculator_license
  ) {
    store.commit('setLoading', false);
    return { path: "/powdersavingscalculator/expo" };
  }

  if (
    to.path.includes("/public/") 
    && !store.state.isAuthenticated
  ) {
    store.commit('setLoading', false);
    store.commit('setPublicInfo', true);
  }

  if (
    // if user is not authenticated and is not navigating to SignIn page, redirect to SignIn page
    !store.state.isAuthenticated &&
    to.name !== "SignIn" &&
    to.name !== "PowderSavingsCalculator" &&
    to.name !== "ResetPasswordByEmail" &&
    to.name !== "ResetPasswordConfirmation" &&
    to.name !== "PublicOptimizationDetail"
  ) {
    // redirect the user to the SignIn page
    store.commit('setLoading', false);
    return { name: "SignIn" };
  }

  if (
    // if user is authenticated and is navigating to SignIn page, redirect to root url
    store.state.isAuthenticated
    && to.name == "SignIn"
  ) {
    // redirect the user to the root path
    return { path: "/" };
  }

  if (
    // if user is authenticated and has not changed password, redirect to ResetPassword page
    store.state.isAuthenticated
    && !store.state.user_data.changed_password
    && to.name !== "ResetPassword"
  ) {
    return { name: "ResetPassword" };
  }

  if (
    // if user is authenticated and has multiple active licenses, redirect to Dashboard
    store.state.total_active_licenses > 1
    && to.name == "Dashboard"
  ) {
    store.commit('setLoading', false);
  }

  if (
    store.state.total_active_licenses == 1
    && (store.state.flightpath_pro_license || store.state.flightpath_standard_license)
    && to.name == "Dashboard"
  ) {
    return {name: "FlightPath"}
  }

  if (
    store.state.total_active_licenses == 1
    && store.state.onsite_service_license
    && to.name == "Dashboard"
  ) {
    return {name: "FlightPathOnSite"}
  }

  if (
    store.state.total_active_licenses == 1
    && store.state.blueprint_light_license
    && to.name == "Dashboard"
  ) {
    return {name: "BlueprintLight"}
  }

  if (
    // if natigating to any route that starts with applications/flightpath and user has no flightpath license
    to.path.startsWith("/applications/flightpath")
    && !to.path.includes("/public/")
    && !store.state.flightpath_pro_license
    && !store.state.flightpath_standard_license
  ) {
    return { name: "Dashboard" };
  }

  if (
    // if natigating to any route that starts with /applications/onsite and user has no onsite service license
    to.path.startsWith("/applications/onsite")
    && !to.path.includes("/public/")
    && !store.state.onsite_service_license
  ) {
    return { name: "Dashboard" };
  }

  store.commit('setLoading', false);

});

export default router;
