<template>
  <div>
    <div class="py-5 container-fluid">
      <div id="topinfo">
        <div class="row mt-4 justify-content-center">
          <div class="card">
            <div class="card-header text-center">
              <h4 class="font-weight-bolder">Powder Saving</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <p class="mr-3">
                  Powder Saving suggests a reduction on the powder amount settings, made possible by the reciprocator
                  optimization results.
                </p>
                <div v-if="powders" class="col-12 mb-3">
                  <label for="powderSelector" class="form-label"
                    >Select Powder for which to optimize powder amount settings:</label
                  >
                  <select
                    id="powderSelector"
                    v-model="selectedPowder"
                    class="form-select"
                    @change="changeSelectedPowder"
                  >
                    <option disabled :value="null">
                      {{ powderName }}
                    </option>
                    <option
                      v-for="powder in powders"
                      :key="powder.id"
                      :value="powder.id"
                      :disabled="powder.id !== selectedPowder"
                    >
                      {{ powder.name }}
                    </option>
                  </select>
                </div>
                <div class="col-12">
                  <h5 class="text-start">Powder Amount Saving</h5>
                  <div class="table-responsive"></div>
                  <table class="table table-border table-bordered">
                    <thead>
                      <tr>
                        <th>
                          Powder throughput [{{
                            $store.state.units_system[$store.state.user_data.unit_system].grams
                          }}/min]
                        </th>
                        <th>Powder Amount Parameters</th>
                        <th>Powder Consumption Chart Comparison</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div class="mt-2 col-6 mb-2">
                            <div v-if="expectedPowderPerMinuteRounded == recommendedExpectedOutputRounded">
                              <p>
                                The optimized
                                <br />
                                gun throughput is:
                              </p>
                            </div>
                            <input
                              v-model="gunExpectedOutput"
                              :class="gunExpectedOutput < 0 ? 'form-control is-invalid' : 'form-control'"
                              type="number"
                              min="0"
                              :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                              :disabled="false"
                              @change="
                                getPowderAmountParametersFromExpectedOutput();
                                drawBarChart();
                              "
                              @blur="saveFormProgress()"
                            />
                          </div>
                        </td>
                        <td>
                          <div v-for="(n, index) in line.total_pistols[0]" :key="n" class="row mb-3">
                            <div class="text-start">
                              <label class="mt-2 mb-2"> Powder Amount Parameter Gun {{ index + 1 }} </label>
                              <div class="col-3 mt-2 mb-2">
                                <input
                                  v-model="powder_amount_parameters[index]"
                                  :class="
                                    powder_amount_parameters[index] > received_powderoutput_data.max_powder_output ||
                                    powder_amount_parameters[index] < received_powderoutput_data.min_powder_output
                                      ? 'form-control is-invalid'
                                      : 'form-control'
                                  "
                                  type="number"
                                  :min="received_powderoutput_data.min_powder_output"
                                  :max="received_powderoutput_data.max_powder_output"
                                  :step="received_powderoutput_data.max_powder_output > 10 ? 1 : 0.1"
                                  @change="
                                    getPowderAmountParametersFromParameter(n - 1, powder_amount_parameters[n - 1]);
                                    drawBarChart();
                                  "
                                  @blur="saveFormProgress()"
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center">
                            <powder-saving-chart
                              :dataset-zero="
                                received_powderoutput_data.desired_output
                                  ? received_powderoutput_data.desired_output
                                  : 0
                              "
                              :dataset-one="expected_powder_per_minute ? expected_powder_per_minute : 0"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- <div class="col-lg-6 justify-content-center"> -->
                  <!-- <powder-saving-chart
                      :dataset-zero="
                        received_powderoutput_data.desired_output ? received_powderoutput_data.desired_output : 0
                      "
                      :dataset-one="expected_powder_per_minute ? expected_powder_per_minute : 0"
                    />
                  </div>
                  <gun-throughput
                    :gun-expected-output="gunExpectedOutput"
                    :line="line"
                    :powder-amount-parameters="powder_amount_parameters"
                  /> -->
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-5">
                  <h5>
                    Thickness Measurements [{{
                      this.$store.state.units_system[$store.state.user_data.unit_system].thickness
                    }}]
                  </h5>
                  <div class="table-responsive">
                    <table class="table table-sm text-center text-xs">
                      <thead>
                        <tr>
                          <th>Measurement</th>
                          <th>Thickness</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(_, index) in thickness_measurements" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td>
                            <input
                              v-model="thickness_measurements[index]"
                              type="number"
                              class="form-control text-center"
                              style="width: 50%; margin: 0 auto"
                              min="0"
                              @change="
                                checkLastRow();
                                saveFormProgress();
                              "
                              @keydown="numericOnly"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button class="mt-2 btn btn-primary" @click="showThicknessGraph()">
                      Show Thickness Distribution Chart
                    </button>
                  </div>
                </div>
                <div class="col-7">
                  <div v-if="this.visit.reciprocatorstage.flightpath_optimizations" class="col-12 mt-3">
                    <gaussian-chart title="Thickness Distribution Chart" chart-name="gaussianPowderSavingChart" />
                    <thickness-measurement-statistic
                      :benchmark_thickness_measurements="benchmark_stage_data.thickness_measurements"
                      :powderoutput_thickness_measurements="received_powderoutput_data.thickness_measurements"
                      :powdersaving_thickness_measurements="thickness_measurements"
                      :reciprocator_thickness_measurements="latestReciprocatorMeasurements"
                    />
                  </div>
                </div>
              </div>
              <div class="row justify-content-center mt-6">
                <button
                  :class="`btn ${valuesUpdated ? 'btn-success' : 'btn-secondary'}`"
                  style="width: 90%"
                  @click="saveFormProgress()"
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <stepper-line-visit :line-id="lineId" :visit="visit" />
    </div>
  </div>
</template>

<script>
import StepperLineVisit from "./components/StepperLineVisit.vue";
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import {
  generateX,
  gaussian,
  generateY,
  calculateMu,
  calculateSigma,
  generateXforMultipleMuSigma,
  generateYwithXvalues,
} from "./utils/gaussianchart";

import {
  useGetVisit,
  useGetBenchmark,
  getThicknessMeasurementsMean,
  getThicknessMeasurementsStd,
  measurementsMin,
  measurementsMax,
  showStatistics,
  numericOnly,
} from "./utils/utils";
import GaussianChart from "@/views/applications/flightpathonsite/components/GaussianChart.vue";
import eventBus from "./utils/eventBus";
import PowderSavingChart from "./components/PowderSavingChart.vue";
// import GunThroughput from "./components/GunThroughput.vue";
import { useGetLine, useGetPowders, useGetCalibratedPowderModels } from "@/views/composables.js";
import ThicknessMeasurementStatistic from "./components/ThicknessMeasurementStatistic.vue";

export default {
  name: "PowderOutput",
  components: {
    StepperLineVisit,
    GaussianChart,
    PowderSavingChart,
    ThicknessMeasurementStatistic,
    // GunThroughput,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
    visitId: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { visit, getVisit } = useGetVisit();
    const { line, getLine } = useGetLine();
    const { benchmark_stage_data, getBenchmarkData } = useGetBenchmark();
    const { powders, getPowders } = useGetPowders();
    const { calibrated_powdermodels, getCalibratedPowderModels } = useGetCalibratedPowderModels();

    return {
      visit,
      getVisit,
      benchmark_stage_data,
      getBenchmarkData,
      line,
      getLine,
      powders,
      getPowders,
      calibrated_powdermodels,
      getCalibratedPowderModels,
    };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      selectedPowder: null,
      thickness_measurements: [null, null, null, null, null],
      last_optimization_powdersavings_percentage: null,
      recommended_expected_output_per_minute: null,
      received_powderoutput_data: {
        min_powder_output: null,
        max_powder_output: null,
        time_interval: 60,
        powderoutput_measurements: null,
        thickness_measurements: Array(5).fill(null),
        desired_output: null,
      },
      received_powdersaving_data: {
        thickness_measurements: Array(5).fill(null),
        desired_output: null,
      },
      max_metric_decimals: 0,
      max_imperial_decimals: 2,

      // Powder Amount Calibration variables
      expected_powder_per_minute: null,
      powdersavingiterationparameters: [],
      powder_amount_parameters: [],
    };
  },

  computed: {
    powderName() {
      const powder = this.powders.find(powder => powder.id === this.benchmark_stage_data.powder);
      if (this.powders && powder) {
        return powder.name;
      }
      return "Selected Powder";
    },
    recommendedExpectedOutputRounded() {
      return this.recommended_expected_output_per_minute
        ? this.recommended_expected_output_per_minute.toFixed(1)
        : null;
    },
    lastOptimizationPowderSavingPercentage() {
      return this.last_optimization_powdersavings_percentage
        ? this.last_optimization_powdersavings_percentage.toFixed(1)
        : null;
    },
    expectedPowderPerMinuteRounded() {
      return this.expected_powder_per_minute ? this.expected_powder_per_minute.toFixed(1) : null;
    },
    valuesUpdated() {
      const isThicknessNotEqual =
        JSON.stringify(this.thickness_measurements) !==
        JSON.stringify(this.received_powdersaving_data.thickness_measurements);

      const isDesiredOutputNotEqual =
        this.expected_powder_per_minute !== this.received_powdersaving_data.desired_output;

      return isThicknessNotEqual || isDesiredOutputNotEqual;
    },
    gunExpectedOutput: {
      get() {
        if (this.expected_powder_per_minute == null || this.expected_powder_per_minute == "") {
          return this.expected_powder_per_minute;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.expected_powder_per_minute * this.$store.state.conversion_factors.g_to_oz).toFixed(2),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.expected_powder_per_minute.toFixed(0));
        } else {
          return this.expected_powder_per_minute;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.expected_powder_per_minute = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.expected_powder_per_minute = value / this.$store.state.conversion_factors.g_to_oz;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.expected_powder_per_minute = value;
          }
        }
      },
    },
    latestReciprocatorMeasurements() {
      if (this.visit.reciprocatorstage.flightpath_optimizations) {
        const optimizations = this.visit.reciprocatorstage.flightpath_optimizations;
        const res = optimizations.length ? optimizations[optimizations.length - 1].thickness_measurements : null;

        return res;
      }
      return [];
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
    this.getData();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    generateX,
    gaussian,
    generateY,
    calculateMu,
    calculateSigma,
    generateXforMultipleMuSigma,
    generateYwithXvalues,
    getThicknessMeasurementsMean,
    getThicknessMeasurementsStd,
    measurementsMin,
    measurementsMax,
    showStatistics,
    numericOnly,
    async getData() {
      await Promise.all([
        this.getVisit(this.visitId),
        this.getLine(this.lineId),
        this.getPowderOutputStageData(),
        this.getBenchmarkData(this.visitId, 0),
        this.getPowders(),
        this.getCalibratedPowderModels(this.lineId),
      ]);

      await this.getPowderSavingStageData();

      if (this.selectedPowder === null || this.selectedPowder === undefined) {
        if (this.benchmark_stage_data.powder !== null && this.benchmark_stage_data.powder !== undefined) {
          this.selectedPowder = this.benchmark_stage_data.powder;
        }
      }

      //   this.selectedPowder = await this.getLastPowderUsedInReciprocatorOptimization();
      // }

      // this.getPowderSavingIterationHistory();

      // this.getNextPowderThroughput()

      // await this.calculateNextPowderThroughput();

      await this.computePowderAmountParameters();

      this.drawBarChart();
      this.showThicknessGraph();

      this.saveFormProgress();
    },
    async computePowderAmountParameters() {
      if (this.isPowderOutputMeasurementsFilled() && this.isValidExpectedThroughput()) {
        try {
          let body = {
            line: this.lineId,
            time_interval: this.received_powderoutput_data.time_interval,
            measures_list: this.generateMeasurementList(this.received_powderoutput_data.powderoutput_measurements),
            powder_per_minute: this.expected_powder_per_minute,
            gun_idx: null,
            powder_amount_param: null,
          };

          let response = await axios.post("/api/v1/fp/computepowderamountcalibration/", body);
          this.powder_amount_parameters = response.data.powder_amount_params;
          this.roundPowderAmountParameters();
        } catch (error) {
          console.error(error);
        }
      }
    },
    async changeSelectedPowder() {
      try {
        await axios.patch("/api/v1/onsite/powdersaving/" + this.visitId + "/", {
          default_selected_powder: this.selectedPowder,
        });
      } catch (error) {
        console.error(error);
      }
    },
    generateMeasurementList(powderoutput_measurements) {
      const results = [];

      powderoutput_measurements.map(gun => {
        const validPairs = gun.gun_measurements.filter(pair => {
          return pair.setting !== "" && pair.weight !== "";
        });

        const result = {
          powderAmount: validPairs.map(pair => parseFloat(pair.setting)),
          emptyBagWeight: Array(validPairs.length).fill(0),
          fullBagWeight: validPairs.map(pair => parseFloat(pair.weight)),
        };

        if (result.powderAmount.length > 0 || result.fullBagWeight.length > 0) {
          results.push(result);
        }
      });

      return results;
    },
    getPowderAmountParametersFromParameter(gun_index, powder_amount_parameter) {
      if (powder_amount_parameter == null || powder_amount_parameter == "") {
        this.clearPowderAmountParameters();
        return;
      }

      if (
        powder_amount_parameter < this.received_powderoutput_data.min_powder_output ||
        powder_amount_parameter > this.received_powderoutput_data.max_powder_output
      ) {
        this.powder_amount_parameters[gun_index] = null;
        this.$swal({
          title: this.$t("Invalid Powder Amount Parameter input"),
          text:
            this.$t("The powder amount parameter is out of range.") +
            " " +
            "Min: " +
            this.received_powderoutput_data.min_powder_output +
            ", " +
            "Max: " +
            this.received_powderoutput_data.max_powder_output,
          icon: "error",
          confirmButtonText: "OK",
        });
      }

      let body = {
        line: this.lineId,
        time_interval: this.received_powderoutput_data.time_interval,
        measures_list: this.generateMeasurementList(this.received_powderoutput_data.powderoutput_measurements),
        powder_per_minute: null,
        gun_idx: gun_index,
        powder_amount_param: powder_amount_parameter,
      };

      axios.post("/api/v1/fp/computepowderamountcalibration/", body).then(response => {
        this.powder_amount_parameters = response.data.powder_amount_params;
        this.roundPowderAmountParameters();
        this.checkPowderAmountResultsRangeAlert();
        this.expected_powder_per_minute = parseFloat(response.data.powder_per_minute.toFixed(1));
        this.checkPowderThroughputAlert();
        this.saveFormProgress();
      });
    },
    roundPowderAmountParameters() {
      this.powder_amount_parameters = this.powder_amount_parameters.map(param => {
        return parseFloat(param.toFixed(1));
      });
    },
    clearPowderAmountParameters() {
      this.powder_amount_parameters = Array(this.line.total_pistols[0]).fill(null);
      this.expected_powder_per_minute = null;
    },
    checkPowderThroughputAlert() {
      if (this.expected_powder_per_minute <= 0) {
        this.$swal({
          title: "Invalid Expected Throughput",
          text: "The expected throughput must be greater than 0.",
          icon: "error",
          confirmButtonText: "OK",
        });

        this.clearPowderAmountParameters();
        if (this.recommended_expected_output_per_minute) {
          this.expected_powder_per_minute = this.recommended_expected_output_per_minute;
        } else {
          this.recommended_expected_output_per_minute = this.received_powderoutput_data.desired_output;
        }
      }
    },
    async getPowderAmountParametersFromExpectedOutput() {
      if (
        this.expected_powder_per_minute == null ||
        this.expected_powder_per_minute == "" ||
        this.expected_powder_per_minute <= 0
      ) {
        if (this.expected_powder_per_minute <= 0) {
          this.$swal({
            title: "Invalid Expected Throughput",
            text: "The expected throughput must be greater than 0.",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.expected_powder_per_minute = null;
        }
        return;
      }
      let body = {
        line: this.lineId,
        time_interval: this.received_powderoutput_data.time_interval,
        measures_list: this.generateMeasurementList(this.received_powderoutput_data.powderoutput_measurements),
        powder_per_minute: this.expected_powder_per_minute,
        gun_idx: null,
        powder_amount_param: null,
      };

      let response = await axios.post("/api/v1/fp/computepowderamountcalibration/", body);

      this.powder_amount_parameters = response.data.powder_amount_params;

      this.checkPowderAmountResultsRangeAlert();
    },
    checkPowderAmountResultsRangeAlert() {
      let calibration_out_of_range_popup_shown = false;
      for (let index = 0; index < this.powder_amount_parameters.length; index++) {
        if (
          this.powder_amount_parameters[index] !== null &&
          this.powder_amount_parameters[index] !== "" &&
          (this.powder_amount_parameters[index] < this.received_powderoutput_data.min_powder_output ||
            this.powder_amount_parameters[index] > this.received_powderoutput_data.max_powder_output)
        ) {
          if (!calibration_out_of_range_popup_shown) {
            this.$swal({
              title: "Calibration Out of Range",
              text: `${"The resulting Powder Amount setting is out of range for Gun"} ${index + 1}.\n Min: ${
                this.received_powderoutput_data.min_powder_output
              }, Max: ${this.received_powderoutput_data.max_powder_output}.
              ${"Obtained Parameter"}: ${this.powder_amount_parameters[index]}`,
              icon: "error",
              confirmButtonText: "OK",
            });
            calibration_out_of_range_popup_shown = true;
          }
        }
      }
    },
    async getPowderOutputStageData() {
      try {
        let response = await axios.get("/api/v1/onsite/powderoutput/" + this.visitId);

        if (response.data !== null) {
          this.received_powderoutput_data.min_powder_output = response.data.min_powder_output;
          this.received_powderoutput_data.max_powder_output = response.data.max_powder_output;

          this.received_powderoutput_data.powderoutput_measurements = response.data.powder_amount_measurements;

          this.received_powderoutput_data.thickness_measurements = response.data.thickness_measurements;

          this.received_powderoutput_data.desired_output = response.data.desired_output;

          if (response.data.desired_output !== null) {
            this.expected_powder_per_minute = response.data.desired_output;
          }
          if (response.data.time_interval !== null) {
            this.received_powderoutput_data.time_interval = response.data.time_interval;
          }
        }
      } catch (error) {
        console.error(error);
      }
      this.$store.state.isLoaded = false;
    },
    async getPowderSavingStageData() {
      const response = await axios.get("/api/v1/onsite/powdersaving/" + this.visitId + "/");

      this.received_powdersaving_data.desired_output = response.data.desired_output;
      this.received_powdersaving_data.thickness_measurements = response.data.thickness_measurements;

      if (response.data.desired_output !== null) {
        this.expected_powder_per_minute = response.data.desired_output;
      }

      if (response.data.default_selected_powder !== null && response.data.default_selected_powder !== undefined) {
        this.selectedPowder = response.data.default_selected_powder;
      }

      if (response.data.thickness_measurements !== null) {
        this.thickness_measurements = response.data.thickness_measurements;
        this.checkLastRow();
      }
    },
    isValidExpectedThroughput() {
      let is_valid_expected_throughput =
        this.expected_powder_per_minute !== null &&
        this.expected_powder_per_minute !== "" &&
        this.expected_powder_per_minute > 0;

      return is_valid_expected_throughput;
    },
    isPowderOutputMeasurementsFilled() {
      let total_empty_measures = this.received_powderoutput_data.powderoutput_measurements
        .map(powderoutput_measurement => powderoutput_measurement.gun_measurements)
        .filter(gun_measurment =>
          gun_measurment.some(
            gun_measurment =>
              gun_measurment.setting == "" ||
              gun_measurment.setting == null ||
              gun_measurment.weight == "" ||
              gun_measurment.weight == null,
          ),
        ).length;

      let is_powder_output_measurements_filled = total_empty_measures == 0;

      return is_powder_output_measurements_filled;
    },
    // async getPowderSavingIterationHistory() {
    //   // TODO FOR GOOD
    //   try {
    //     const response = await axios.get("/api/v1/onsite/powdersavingiterationparameters/" + this.visitId + "/" + this.selectedPowder + "/");
    //     console.log("powdersavingiterationparameters");
    //     console.log(response.data);
    //     this.powdersavingiterationparameters = response.data;
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    // async getNextPowderThroughput() {
    //   // TODO FOR GOOD
    //   if (this.powdersavingiterationparameters.length == 0) {
    //     try {
    //       const response = await axios.get("/api/v1/onsite/lastthicknessmeasures/" + this.visitId + "/" + this.selectedPowder + "/")
    //       console.log(response.data);
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   }
    // },
    // async getLastPowderUsedInReciprocatorOptimization() {
    //   const response = await axios.get("/api/v1/onsite/lastpowderusedinreciprocatoroptimization/" + this.visitId + "/");
    //   if (response.status === 404) {
    //     return null;
    //   }
    //   return response.data["last_powder_used_in_reciprocator_optimization"];
    // },
    getFormProgressStatus() {
      const PROGRESS_STATUS_PENDING = "Pending";
      const PROGRESS_STATUS_IN_PROGRESS = "In Progress";
      const PROGRESS_STATUS_COMPLETED = "Completed";

      if (
        this.isValidExpectedThroughput() &&
        this.thickness_measurements.filter(m => m !== null && m !== "").length >= 2
      ) {
        return PROGRESS_STATUS_COMPLETED;
      } else if (this.isValidExpectedThroughput()) {
        return PROGRESS_STATUS_IN_PROGRESS;
      }

      return PROGRESS_STATUS_PENDING;
    },
    async saveFormProgress() {
      try {
        const nonEmptyThicknessMeasurements = this.thickness_measurements.filter(
          m => m !== null && m !== "" && m !== 0,
        );
        await axios.patch("/api/v1/onsite/powdersaving/" + this.visitId + "/", {
          thickness_measurements: nonEmptyThicknessMeasurements,
          desired_output: this.expected_powder_per_minute,
          progress_status: this.getFormProgressStatus(),
        });
        await this.getVisit(this.visitId);
      } catch (error) {
        console.error(error);
      }
    },
    checkLastRow() {
      while (this.thickness_measurements.length < 5) {
        this.thickness_measurements.push("");
      }

      if (this.thickness_measurements.every(m => m !== null && m !== "" && m !== 0)) {
        this.thickness_measurements.push("");
      } else if (
        (this.thickness_measurements[this.thickness_measurements.length - 1] == null ||
          this.thickness_measurements[this.thickness_measurements.length - 1] == "") &&
        (this.thickness_measurements[this.thickness_measurements.length - 2] == null ||
          this.thickness_measurements[this.thickness_measurements.length - 2] == "") &&
        this.thickness_measurements.length > 5
      ) {
        this.thickness_measurements.pop();
      }
    },
    generateXLabels() {
      const mu = this.calculateMu(this.thickness_measurements);
      const sigma = this.calculateSigma(this.thickness_measurements, mu);

      const benchmarkMu = this.calculateMu(this.benchmark_stage_data.thickness_measurements);
      const benchmarkSigma = this.calculateSigma(this.benchmark_stage_data.thickness_measurements, benchmarkMu);

      const powderOutputMu = this.calculateMu(this.received_powderoutput_data.thickness_measurements);
      const powderOutputSigma = this.calculateSigma(
        this.received_powderoutput_data.thickness_measurements,
        powderOutputMu,
      );

      const reciprocatorMu = this.calculateMu(this.received_powderoutput_data.thickness_measurements);
      const reciprocatorSigma = this.calculateSigma(
        this.received_powderoutput_data.thickness_measurements,
        powderOutputMu,
      );

      this.$store.state.gaussianPowderSavingChart.labels = this.generateXforMultipleMuSigma([
        [mu, sigma],
        [benchmarkMu, benchmarkSigma],
        [powderOutputMu, powderOutputSigma],
        [reciprocatorMu, reciprocatorSigma],
      ]);

      const Xarray = this.$store.state.gaussianPowderSavingChart.labels.map(num => parseFloat(num));

      this.$store.state.minTargetThickness = Xarray.findIndex(
        num => num > this.benchmark_stage_data.minimum_target_thickness,
      );
      this.$store.state.maxTargetThickness = Xarray.findIndex(
        num => num > this.benchmark_stage_data.maximum_target_thickness,
      );
    },
    benchmarkThicknessMeasurementsUpdateChartData() {
      if (this.benchmark_stage_data.thickness_measurements == null) {
        return;
      }

      const benchmarkMu = this.calculateMu(this.benchmark_stage_data.thickness_measurements);
      const benchmarkSigma = this.calculateSigma(this.benchmark_stage_data.thickness_measurements, benchmarkMu);

      this.$store.state.gaussianPowderSavingChart.datasets[0] = {
        label: "Initial Benchmark",
        data: this.generateYwithXvalues(
          benchmarkSigma,
          benchmarkMu,
          this.$store.state.gaussianPowderSavingChart.labels,
        ),
      };
    },
    powderOutputThicknessMeasurementsUpdateChartData() {
      if (
        this.received_powderoutput_data.thickness_measurements == null &&
        this.received_powderoutput_data.thickness_measurements.length <= 2
      ) {
        return;
      }

      const powderOutputMu = this.calculateMu(this.received_powderoutput_data.thickness_measurements);
      const powderOutputSigma = this.calculateSigma(
        this.received_powderoutput_data.thickness_measurements,
        powderOutputMu,
      );

      const arr = this.$store.state.gaussianPowderSavingChart.labels.map(num => parseFloat(num));

      let index = arr.findIndex(num => num > this.benchmark_stage_data.minimum_target_thickness);
      let index_max = arr.findIndex(num => num > this.benchmark_stage_data.maximum_target_thickness);

      this.$store.state.minTargetThickness = index > 0 ? index - 1 : index;
      this.$store.state.maxTargetThickness = index_max > 0 ? index_max - 1 : arr.length - 1;

      this.$store.state.gaussianPowderSavingChart.datasets[1] = {
        label: "Guns Equalization stage",
        data: this.generateYwithXvalues(
          powderOutputSigma,
          powderOutputMu,
          this.$store.state.gaussianPowderSavingChart.labels,
        ),
      };
    },
    thicknessMeasurementsUpdateChartData() {
      const mu = this.calculateMu(this.thickness_measurements);
      const sigma = this.calculateSigma(this.thickness_measurements, mu);

      this.$store.state.gaussianPowderSavingChart.datasets[3] = {
        label: "Powder Saving stage",
        data: this.generateYwithXvalues(sigma, mu, this.$store.state.gaussianPowderSavingChart.labels),
      };
    },
    thicknessMeasurementsReciprocatorStageUpdateChartData() {
      const mu = this.$store.state.gaussianReciprocatorMu;
      const sigma = this.$store.state.gaussianReciprocatorSigma;

      this.$store.state.gaussianPowderSavingChart.datasets[2] = {
        label: "Reciprocator stage",
        data: this.generateYwithXvalues(sigma, mu, this.$store.state.gaussianReciprocatorChart.labels),
      };
    },
    addPair(gunIndex) {
      this.powderoutput_measurements[gunIndex].gun_measurements.push({ setting: "", weight: "" });
    },
    deletePair(gunIndex) {
      this.powderoutput_measurements[gunIndex].gun_measurements.pop();
    },
    drawBarChart() {
      eventBus.emit("draw-bar-chart");
    },
    showThicknessGraph() {
      this.generateXLabels();
      this.benchmarkThicknessMeasurementsUpdateChartData();
      this.powderOutputThicknessMeasurementsUpdateChartData();
      this.thicknessMeasurementsReciprocatorStageUpdateChartData();
      this.thicknessMeasurementsUpdateChartData();
      eventBus.emit("draw-gaussian-chart");
    },
  },
};
</script>
<style scoped>
.table-bordered {
  border: 1px solid #dee2e6;
  border-collapse: collapse; /* Ensure borders are collapsed */
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-border tbody tr:last-child td {
  border-width: 1px;
}

.chart-canvas {
  max-height: 400px;
}
</style>
