<template>
  <div class="row justify-content-between mt-2">
    <div v-if="showStatistics(benchmark_thickness_measurements)" class="card col mt-2 text-center">
      <h6 class="font-weight-bolder mb-4">Initial Benchmark</h6>
      <h6>Std Dev: {{ getThicknessMeasurementsStd(benchmark_thickness_measurements) }}</h6>
      <h6>Mean: {{ getThicknessMeasurementsMean(benchmark_thickness_measurements) }}</h6>
      <h6>Min: {{ measurementsMin(benchmark_thickness_measurements) }}</h6>
      <h6>Max: {{ measurementsMax(benchmark_thickness_measurements) }}</h6>
    </div>

    <div v-if="showStatistics(powderoutput_thickness_measurements)" class="card col text-center m-1 mt-2">
      <h6 class="font-weight-bolder mb-4">Guns Equalization stage</h6>
      <h6
        :class="{
          'font-weight-bolder':
            getStdDifferencePercentage(powderoutput_thickness_measurements, benchmark_thickness_measurements) !==
              null &&
            !reciprocator_thickness_measurements &&
            !powdersaving_thickness_measurements,
        }"
      >
        Std Dev: {{ getThicknessMeasurementsStd(powderoutput_thickness_measurements) }}
        <span
          v-if="
            getStdDifferencePercentage(powderoutput_thickness_measurements, benchmark_thickness_measurements) !==
              null &&
            !reciprocator_thickness_measurements &&
            !powdersaving_thickness_measurements
          "
        >
          <span class="badge bg-success text-dark">
            {{ getStdDifferencePercentage(powderoutput_thickness_measurements, benchmark_thickness_measurements) }}%
          </span>
        </span>
      </h6>
      <h6>Mean: {{ getThicknessMeasurementsMean(powderoutput_thickness_measurements) }}</h6>

      <h6>Min: {{ measurementsMin(powderoutput_thickness_measurements) }}</h6>
      <h6>Max: {{ measurementsMax(powderoutput_thickness_measurements) }}</h6>
    </div>

    <div v-if="showStatistics(reciprocator_thickness_measurements)" class="card col mt-2 text-center m-1">
      <h6 class="font-weight-bolder mb-4">Reciprocator stage</h6>
      <h6
        :class="{
          'font-weight-bolder':
            getStdDifferencePercentage(reciprocator_thickness_measurements, benchmark_thickness_measurements) !==
              null &&
            powderoutput_thickness_measurements &&
            !powdersaving_thickness_measurements,
        }"
      >
        Std Dev: {{ getThicknessMeasurementsStd(reciprocator_thickness_measurements) }}
        <span
          v-if="
            getStdDifferencePercentage(reciprocator_thickness_measurements, benchmark_thickness_measurements) !==
              null &&
            powderoutput_thickness_measurements &&
            !powdersaving_thickness_measurements
          "
        >
          <span class="badge bg-success text-dark">
            {{ getStdDifferencePercentage(reciprocator_thickness_measurements, benchmark_thickness_measurements) }}%
          </span>
        </span>
      </h6>
      <h6>Mean: {{ getThicknessMeasurementsMean(reciprocator_thickness_measurements) }}</h6>
      <h6>Min: {{ measurementsMin(reciprocator_thickness_measurements) }}</h6>
      <h6>Max: {{ measurementsMax(reciprocator_thickness_measurements) }}</h6>
    </div>

    <div v-if="showStatistics(powdersaving_thickness_measurements)" class="card col mt-2 text-center m-1">
      <h6 class="font-weight-bolder mb-4">Powder Saving stage</h6>
      <h6
        :class="{
          'font-weight-bolder':
            getStdDifferencePercentage(powdersaving_thickness_measurements, benchmark_thickness_measurements) !== null,
        }"
      >
        Std Dev: {{ getThicknessMeasurementsStd(powdersaving_thickness_measurements) }}
        <span
          v-if="
            getStdDifferencePercentage(powdersaving_thickness_measurements, benchmark_thickness_measurements) !==
              null &&
            powderoutput_thickness_measurements &&
            reciprocator_thickness_measurements
          "
        >
          <span class="badge bg-success text-dark">
            {{ getStdDifferencePercentage(powdersaving_thickness_measurements, benchmark_thickness_measurements) }}%
          </span>
        </span>
      </h6>
      <h6>Mean: {{ getThicknessMeasurementsMean(powdersaving_thickness_measurements) }}</h6>
      <h6>Min: {{ measurementsMin(powdersaving_thickness_measurements) }}</h6>
      <h6>Max: {{ measurementsMax(powdersaving_thickness_measurements) }}</h6>
    </div>
  </div>
</template>

<script>
import {
  getThicknessMeasurementsMean,
  getThicknessMeasurementsStd,
  measurementsMin,
  measurementsMax,
  showStatistics,
  getStdDifferencePercentage,
} from "../utils/utils";

export default {
  name: "ThicknessMeasurementStatistic",

  props: {
    benchmark_thickness_measurements: {
      type: Array,
      required: true,
    },
    powderoutput_thickness_measurements: {
      type: Array,
      required: false,
    },
    reciprocator_thickness_measurements: {
      type: Array,
      required: false,
    },
    powdersaving_thickness_measurements: {
      type: Array,
      required: false,
    },
  },
  methods: {
    getThicknessMeasurementsMean,
    getThicknessMeasurementsStd,
    measurementsMin,
    measurementsMax,
    showStatistics,
    getStdDifferencePercentage,
  },
};
</script>
