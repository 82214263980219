<template>
  <canvas
    id="powder-saving-chart"
    class="chart-canvas"
    height="100%"
    style="max-width: 100%; min-width: 250px"
  >
  </canvas>
</template>

<script>
import Chart from "chart.js/auto";
import eventBus from "../utils/eventBus";

export default {
  name: "PowderSavingChart",
  props: {
    datasetZero: {
      type: Number,
      required: true,
    },
    datasetOne: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      chart: {
        labels: [],
        datasets: [],
      },
    };
  },
  computed: {},
  mounted() {
    eventBus.on("draw-bar-chart", this.drawBarChart);
  },
  beforeUnmount() {
    eventBus.off("draw-bar-chart", this.drawBarChart);
  },
  methods: {
    createBarChartConfig() {
      const backgroundColors = ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"];
      const borderColors = ["rgb(255, 99, 132)", "rgb(54, 162, 235)"];
      const optimizedBackgroundColors = ["rgba(54, 162, 235, 0.2)", "rgba(54, 162, 235, 0.2)"];
      const optimizedBorderColors = ["rgba(84, 182, 235, 0.8)", "rgb(84, 182, 245)"];

      const data = {
        labels: ["Consumption"],
        datasets: [
          {
            label: "Previous Powder Output",
            data: [this.datasetZero],
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
          },
          {
            label: "Optimized Powder Output",
            data: [this.datasetOne],
            backgroundColor: optimizedBackgroundColors,
            borderColor: optimizedBorderColors,
            borderWidth: 1,
          },
        ],
      };

      const config = {
        type: "bar",
        data: data,
        options: {
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Total Guns Throughput g/min",
              },
            },
          },
        },
      };
      return config;
    },
    drawBarChart() {
      var canvas = document.getElementById("powder-saving-chart").getContext("2d");

      let chartStatus = Chart.getChart("powder-saving-chart");
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      new Chart(canvas, this.createBarChartConfig());
    },
  },
};
</script>
