<template>
  <div>
    <h5 class="mb-1">Guns Equalization</h5>
    <div class="d-flex px-2">
      <mini-statistics-card
        title="Gun output"
        :value="`${gunExpectedOutput} [${$store.state.units_system[$store.state.user_data.unit_system].grams}/min]`"
        percentage=""
        :icon="{ component: 'ni ni ni-compass-04', background: 'bg-gradient-info' }"
        class-content="px-2"
      />
    </div>
    <h6 class="px-3">Powder Amount Parameters to equalize all guns:</h6>
    <div class="row">
      <div v-for="(n, index) in line.total_pistols[0]" :key="n" class="col-lg-2 col-md-4 col-sm-6 d-flex">
        <mini-statistics-card
          :title="' Gun ' + (index + 1)"
          :value="`${powderAmountParameters[index]}`"
          :percentage="{ value: '200', color: 'text-success' }"
          :icon="{ component: 'ni ni-sound-wave', background: 'bg-gradient-info' }"
          class-content="px-2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MiniStatisticsCard from "@/components/MiniStatisticsCard.vue";

export default {
  name: "GunThroughput",
  components: {
    MiniStatisticsCard,
  },
  props: {
    gunExpectedOutput: {
      type: Number,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    powderAmountParameters: {
      type: Array,
      required: true,
    },
  },
};
</script>
