<template>
  <div class="py-5 container-fluid">
    <div id="topinfo">
      <div class="row mt-4 justify-content-center">
        <div class="col-12">
          <div class="card">
            <div class="card-header pb-0 text-center my-3">
              <h4 class="font-weight-bolder">{{ $t("Benchmark & Target") }}</h4>
            </div>
            <div
              class="card-body mb-0 pb-3"
              style="padding-top: 0.5rem"
            >
              <div class="row">
                <div class="col-6 mt-3">
                  <div class="row mb-3">
                    <h5>Target Thickness</h5>
                    <div class="row mb-3">
                      <div class="col-5">
                        <label
                          >Min [{{
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].thickness
                          }}]</label
                        >
                        <input
                          v-model="benchmark_form.minimum_target_thickness"
                          type="number"
                          class="form-control my-2"
                          placeholder="MIN"
                          @keydown="numericOnly"
                        />
                      </div>
                      <div class="col-5">
                        <label
                          >Max [{{
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].thickness
                          }}]</label
                        >
                        <input
                          v-model="benchmark_form.maximum_target_thickness"
                          type="number"
                          class="form-control my-2"
                          placeholder="MAX"
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <h5>Line Settings</h5>
                    <div class="row mb-3">
                      <div class="col-5">
                        <label>Reciprocator Period [s]</label>
                        <input
                          v-model="benchmark_form.reciprocator_period"
                          type="number"
                          class="form-control my-2"
                          placeholder="PERIOD"
                          @keydown="numericOnly"
                        />
                      </div>
                      <div class="col-5">
                        <label
                          >Reciprocator Stroke [{{
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                          }}]</label
                        >
                        <input
                          v-model="gunsMovementRange"
                          type="number"
                          class="form-control my-2"
                          placeholder="STROKE"
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-5">
                        <label
                          >Line Speed [{{
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_minutes
                          }}]</label
                        >
                        <input
                          v-model="lineSpeed"
                          type="number"
                          min="0"
                          class="form-control my-2"
                          placeholder="LINE SPEED"
                          @keydown="numericOnly"
                        />
                      </div>
                      <div class="col-5">
                        <label
                          >Covered Height [{{
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                          }}]</label
                        >
                        <input
                          v-model="coatedHeight"
                          type="number"
                          class="form-control my-2"
                          placeholder="COVERED HEIGHT"
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-5">
                        <label>Nozzle Angle</label>
                        <select
                          id="choices-category"
                          v-model="benchmark_form.nozzle_angle"
                          class="form-control my-2"
                          style="appearance: listbox"
                        >
                          <option
                            disabled
                            :value="null"
                          >
                            Please select one
                          </option>
                          <option :value="0">{{ "0 degrees" }} (3h)</option>
                          <option :value="15">{{ "15 degrees" }} (2:30h)</option>
                          <option :value="30">{{ "30 degrees" }} (2h)</option>
                          <option :value="45">{{ "45 degrees" }} (1:30h)</option>
                          <option :value="60">{{ "60 degrees" }} (1h)</option>
                          <option :value="75">{{ "75 degrees" }} (0:30h)</option>
                          <option :value="90">{{ "90 degrees" }} (12h)</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 mt-3">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="text-center">Gun Settings</h5>
                      <div
                        class="table-responsive"
                        style="overflow-x: auto"
                      >
                        <table class="table table-sm text-center text-xs">
                          <thead>
                            <tr>
                              <th class="text-center">Gun</th>
                              <th class="text-center">Voltage</th>
                              <th class="text-center">Amps</th>
                              <th class="text-center">Air</th>
                              <th class="text-center">Distance</th>
                              <th class="text-center">Powder Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(gun, index) in benchmark_form.gun_settings"
                              :key="index"
                            >
                              <td>{{ index + 1 }}</td>
                              <td>
                                <input
                                  v-model="gun.voltage"
                                  type="text"
                                  class="form-control text-center text-sm"
                                  style="width: 70%; margin: 0 auto"
                                  @keydown="numericOnly"
                                  @change="autoFillGunSettings(index, 'voltage')"
                                />
                              </td>
                              <td>
                                <input
                                  v-model="gun.amps"
                                  type="text"
                                  class="form-control text-center text-sm"
                                  style="width: 70%; margin: 0 auto"
                                  @keydown="numericOnly"
                                  @change="autoFillGunSettings(index, 'amps')"
                                />
                              </td>
                              <td>
                                <input
                                  v-model="gun.air"
                                  type="text"
                                  class="form-control text-center text-sm"
                                  style="width: 70%; margin: 0 auto"
                                  @keydown="numericOnly"
                                  @change="autoFillGunSettings(index, 'air')"
                                />
                              </td>
                              <td>
                                <input
                                  v-model="gun.distance"
                                  type="text"
                                  class="form-control text-center text-sm"
                                  style="width: 70%; margin: 0 auto"
                                  @keydown="numericOnly"
                                  @change="autoFillGunSettings(index, 'distance')"
                                />
                              </td>
                              <td>
                                <input
                                  v-model="gun.powder"
                                  type="text"
                                  class="form-control text-center text-sm"
                                  style="width: 70%; margin: 0 auto"
                                  @keydown="numericOnly"
                                  @change="autoFillGunSettings(index, 'powder')"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <h5 class="mb-3">Powder Selection</h5>
                <div class="row mb-3">
                  <div class="col-6">
                    <label>Powder</label>
                    <select
                      id="choices-category"
                      v-model="benchmark_form.powder"
                      class="form-control col-4 my-2 mr-2"
                      style="appearance: listbox"
                    >
                      <option
                        disabled
                        :value="null"
                      >
                        Please select one
                      </option>
                      <option
                        v-for="powder in powders.slice().reverse()"
                        :key="powder.id"
                        :value="powder.id"
                      >
                        {{ powder.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-4 text-center mt-4">
                    <button
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#modal-form"
                      class="mt-2 btn btn-primary ml-3"
                    >
                      Create new Powder
                    </button>
                    <div
                      id="modal-form"
                      ref="modal"
                      class="modal fade"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="modal-form"
                      aria-hidden="true"
                      style="z-index: 1050 !important"
                    >
                      <div
                        class="modal-dialog modal-dialog-centered modal-sm"
                        role="document"
                      >
                        <div
                          class="modal-content"
                          style="text-align: left !important"
                        >
                          <div class="modal-body p-0">
                            <div class="card card-plain">
                              <div class="card-body justify-content-left">
                                <button
                                  ref="dismissButton"
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label=""
                                ></button>
                                <form
                                  role="form"
                                  class=""
                                >
                                  <label>Powder name</label>
                                  <div class="input-group mb-3">
                                    <input
                                      v-model="newPowderName"
                                      type="text"
                                      :class="powder_name_input_error ? `form-control is-invalid` : `form-control`"
                                      placeholder="Powder name"
                                      @input="powder_name_input_error = false"
                                    />
                                  </div>
                                  <label>Powder Manufacturer</label>
                                  <div class="input-group mb-3">
                                    <input
                                      v-model="newPowderManufacturer"
                                      type="text"
                                      :class="
                                        powder_manufacturer_input_error ? `form-control is-invalid` : `form-control`
                                      "
                                      placeholder="Powder Manufacturer"
                                      @input="powder_manufacturer_input_error = false"
                                    />
                                  </div>
                                  <label>Powder ID (Optional)</label>
                                  <div class="input-group mb-3">
                                    <input
                                      v-model="newPowderId"
                                      type="text"
                                      class="form-control"
                                      placeholder="Powder ID"
                                    />
                                  </div>
                                  <div class="text-center">
                                    <button
                                      type="button"
                                      class="btn bg-gradient-success btn-lg w-100 mt-4 mb-0"
                                      @click="createPowder()"
                                    >
                                      Create Powder
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-12">
                      <h5>
                        Thickness Measurements [{{
                          this.$store.state.units_system[this.$store.state.user_data.unit_system].thickness
                        }}]
                      </h5>
                      <div class="table-responsive">
                        <table class="table table-sm text-center text-xs">
                          <thead>
                            <tr>
                              <th>Measurement</th>
                              <th>Thickness</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(_, index) in thickness_measurements"
                              :key="index"
                            >
                              <td>{{ index + 1 }}</td>
                              <td>
                                <input
                                  v-model="thickness_measurements[index]"
                                  type="number"
                                  class="form-control text-center"
                                  style="width: 50%; margin: 0 auto"
                                  min="0"
                                  @change="checkLastRow()"
                                  @keydown="numericOnly"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="text-center mt-0 mb-0">
                          <button
                            class="mt-2 text-center btn btn-primary"
                            @click="showGraph"
                          >
                            Show thickness distribution chart
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <gaussian-chart
                    title="Thickness Distribution Chart"
                    chart-name="gaussianBenchmarkChart"
                  />
                  <thickness-measurement-statistic :benchmark_thickness_measurements="thickness_measurements" />
                </div>
              </div>
            </div>
            <div class="mt-2 d-flex flex-column justify-content-center align-items-center">
              <button
                type="button"
                class="mt-4 mb-4 btn"
                :class="benchmarkFormUpdated ? 'bg-gradient-success' : 'bg-gradient-secondary'"
                style="width: 60%"
                @click="updateBenchmarkData"
              >
                {{ $t("Save") }}
              </button>
            </div>
          </div>
        </div>
        <stepper-line-visit
          :line-id="lineId"
          :visit="visit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import logo from "@/assets/img/logo-ct-white.png";

import axios from "axios";
import { useGetPowders } from "@/views/composables.js";

import StepperLineVisit from "./components/StepperLineVisit.vue";
import { generateX, gaussian, generateY, calculateMu, calculateSigma } from "./utils/gaussianchart.js";
import GaussianChart from "./components/GaussianChart.vue";
import ThicknessMeasurementStatistic from "./components/ThicknessMeasurementStatistic.vue";
import eventBus from "./utils/eventBus";

import {
  useGetVisit,
  useGetBenchmark,
  getThicknessMeasurementsMean,
  getThicknessMeasurementsStd,
  measurementsMin,
  measurementsMax,
  showStatistics,
  numericOnly,
} from "./utils/utils";
import { useGetLine } from "@/views/composables.js";

export default {
  name: "BenchmarkAndTarget",
  components: {
    StepperLineVisit,
    GaussianChart,
    ThicknessMeasurementStatistic,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
    visitId: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { visit, getVisit } = useGetVisit();
    const { line, getLine } = useGetLine();
    const { benchmark_stage_data, getBenchmarkData } = useGetBenchmark();
    const { powders, getPowders } = useGetPowders();
    return { visit, getVisit, benchmark_stage_data, getBenchmarkData, line, getLine, powders, getPowders };
  },
  data() {
    return {
      benchmark_form: {
        minimum_target_thickness: null,
        maximum_target_thickness: null,
        reciprocator_period: null,
        guns_movement_range: null,
        line_speed: null,
        coated_height: null,
        nozzle_angle: null,
        gun_settings: null,
        thickness_measurements: Array(5).fill(null),
        powder: null,
      },
      newPowderName: "",
      powder_name_input_error: false,
      newPowderManufacturer: "",
      powder_manufacturer_input_error: false,
      newPowderId: "",
      thickness_measurements: Array(5).fill(null),
      logo,
      max_imperial_decimals: 2,
      max_metric_decimals: 1,
    };
  },
  computed: {
    benchmarkFormUpdated() {
      return (
        JSON.stringify(this.benchmark_form) !== JSON.stringify(this.benchmark_stage_data) ||
        JSON.stringify(this.thickness_measurements.filter(m => m !== null && m !== "")) !==
          JSON.stringify(this.benchmark_stage_data.thickness_measurements) ||
        this.benchmark_form.powder !== this.benchmark_stage_data.powder
      );
    },
    gunsMovementRange: {
      get() {
        if (this.benchmark_form.guns_movement_range == null || this.benchmark_form.guns_movement_range == "") {
          return this.benchmark_form.guns_movement_range;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.benchmark_form.guns_movement_range * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.benchmark_form.guns_movement_range.toFixed(this.max_metric_decimals));
        } else {
          return this.benchmark_form.guns_movement_range;
        }
      },
      set(value) {
        if (value == null || this.benchmark_form.guns_movement_range == "") {
          this.benchmark_form.guns_movement_range = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.benchmark_form.guns_movement_range = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.benchmark_form.guns_movement_range = value;
          }
        }
      },
    },
    lineSpeed: {
      get() {
        if (this.benchmark_form.line_speed == null || this.benchmark_form.line_speed === "") {
          return this.benchmark_form.line_speed;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.benchmark_form.line_speed * this.$store.state.conversion_factors.m_to_ft).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.benchmark_form.line_speed.toFixed(this.max_metric_decimals));
        } else {
          return parseFloat(this.benchmark_form.line_speed);
        }
      },
      set(value) {
        if (value == null || value === "") {
          this.benchmark_form.line_speed = value;
        } else {
          value = parseFloat(value);
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.benchmark_form.line_speed = value / this.$store.state.conversion_factors.m_to_ft;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.benchmark_form.line_speed = value;
          }
        }
      },
    },
    coatedHeight: {
      get() {
        if (this.benchmark_form.coated_height == null || this.benchmark_form.coated_height == "") {
          return this.benchmark_form.coated_height;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.benchmark_form.coated_height * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.benchmark_form.coated_height.toFixed(this.max_metric_decimals));
        } else {
          return this.benchmark_form.coated_height;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.benchmark_form.coated_height = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.benchmark_form.coated_height = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.benchmark_form.coated_height = value;
          }
        }
      },
    },
    progressStatus() {
      const fields = [
        this.benchmark_form.minimum_target_thickness,
        this.benchmark_form.maximum_target_thickness,
        this.benchmark_form.reciprocator_period,
        this.benchmark_form.guns_movement_range,
        this.thickness_measurements,
        this.benchmark_form.line_speed,
        this.benchmark_form.coated_height,
        this.benchmark_form.nozzle_angle,
      ];
      let notEmptyMeasurements = this.thickness_measurements.filter(m => m !== null && m !== "");
      if (
        fields.every(field => field !== null || field !== "") &&
        this.benchmark_form.gun_settings.every(gun =>
          Object.values(gun).every(value => value !== null || value !== ""),
        ) &&
        notEmptyMeasurements.length >= 5
      ) {
        return "Completed";
      } else if (
        fields.some(field => field !== null || field !== "") ||
        this.benchmark_form.gun_settings.some(gun =>
          Object.values(gun).some(value => value !== null || value !== ""),
        ) ||
        notEmptyMeasurements.length > 0
      ) {
        return "In Progress";
      } else {
        return "Pending";
      }
    },
  },
  mounted() {
    this.getData();
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    getThicknessMeasurementsMean,
    getThicknessMeasurementsStd,
    measurementsMin,
    measurementsMax,
    showStatistics,
    numericOnly,
    autoFillGunSettings(index, property_key) {
      if (index !== 0) {
        return;
      }

      const inputValue = this.benchmark_form.gun_settings[0][property_key];
      const emptyInputValue = inputValue === "" || inputValue === null;

      const isColumnEmpty = this.benchmark_form.gun_settings
        .slice(1)
        .every(gun => gun[property_key] === "" || gun[property_key] === null);

      if (!emptyInputValue && isColumnEmpty) {
        this.benchmark_form.gun_settings.forEach(gun => {
          gun[property_key] = inputValue;
        });
        return;
      }

      if (emptyInputValue && !isColumnEmpty) {
        const allValuesAreTheSame = this.benchmark_form.gun_settings
          .slice(1)
          .every(gun => gun[property_key] === this.benchmark_form.gun_settings[1][property_key]);
        if (allValuesAreTheSame) {
          this.benchmark_form.gun_settings.forEach(gun => {
            gun[property_key] = "";
          });
        }
      }
    },
    showGraph() {
      this.thicknessMeasurementsUpdateChartData();
      eventBus.emit("draw-gaussian-chart");
    },
    checkLastRow() {
      while (this.thickness_measurements.length < 5) {
        this.thickness_measurements.push("");
      }

      if (this.thickness_measurements.every(m => m !== null && m !== "" && m !== 0)) {
        this.thickness_measurements.push("");
      } else if (
        (this.thickness_measurements[this.thickness_measurements.length - 1] == null ||
          this.thickness_measurements[this.thickness_measurements.length - 1] == "") &&
        (this.thickness_measurements[this.thickness_measurements.length - 2] == null ||
          this.thickness_measurements[this.thickness_measurements.length - 2] == "") &&
        this.thickness_measurements.length > 5
      ) {
        this.thickness_measurements.pop();
      }
    },
    async getData() {
      await this.getLine(this.lineId);
      await this.getVisit(this.visitId);
      await this.getBenchmarkData(this.visitId, this.line.total_pistols[0]);
      this.benchmark_stage_data.total_pistols = this.line.total_pistols[0];
      this.benchmark_form = JSON.parse(JSON.stringify(this.benchmark_stage_data));
      this.thickness_measurements = JSON.parse(JSON.stringify(this.benchmark_stage_data.thickness_measurements));
      this.checkLastRow();
      await this.getPowders();
      this.filterPowdersByLine();

      this.$store.state.isLoaded = false;

      this.showGraph();
    },
    filterPowdersByLine() {
      this.powders = this.powders.filter(powder => String(powder.line) == this.lineId);
    },
    checkFilledCreatePowderForm() {
      if (this.newPowderName == "") {
        this.powder_name_input_error = true;
      }
      if (this.newPowderManufacturer == "") {
        this.powder_manufacturer_input_error = true;
      }
      if (this.powder_name_input_error || this.powder_manufacturer_input_error) {
        this.$swal({
          title: "Please fill in all required fields",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
        return false;
      }
      return true;
    },
    closeModal() {
      const dismissButton = this.$refs.dismissButton;
      if (dismissButton) {
        dismissButton.click();
      }
    },
    createPowder() {
      if (!this.checkFilledCreatePowderForm()) {
        return;
      }
      axios
        .post("/api/v1/onsite/powder/", {
          line: this.lineId,
          name: this.newPowderName,
          powder_manufacturer: this.newPowderManufacturer,
          powder_id: this.newPowderId,
        })
        .then(() => {
          this.newPowderName = "";
          this.newPowderManufacturer = "";
          this.newPowderId = "";
          this.getPowders();
          this.filterPowdersByLine();
        })
        .catch(error => {
          console.error(error);
        });
      this.closeModal();
    },
    async updateBenchmarkData() {
      let nonEmptyThicknessMeasurements = this.thickness_measurements.filter(m => m !== null && m !== "" && m !== 0);
      try {
        await axios.put(`/api/v1/onsite/benchmarkstage/` + this.visitId + "/", {
          visit: this.visitId,
          minimum_target_thickness: this.benchmark_form.minimum_target_thickness,
          maximum_target_thickness: this.benchmark_form.maximum_target_thickness,
          reciprocator_period: this.benchmark_form.reciprocator_period,
          guns_movement_range: this.benchmark_form.guns_movement_range,
          line_speed: this.benchmark_form.line_speed,
          coated_height: this.benchmark_form.coated_height,
          nozzle_angle: this.benchmark_form.nozzle_angle ? this.benchmark_form.nozzle_angle : 0,
          gun_settings: this.benchmark_form.gun_settings,
          thickness_measurements: nonEmptyThicknessMeasurements,
          progress_status: this.progressStatus,
          powder: this.benchmark_form.powder,
        });

        await this.getData(this.visitId);

        this.$swal({
          title: this.$t("Changes saved"),
          text: this.$t("Benchmark & Target") + " " + this.$t("was saved successfully"),
          icon: "success",
          confirmButtonText: "OK",
        }).then(this.getBenchmarkData(this.visitId, this.line.total_pistols[0]));
      } catch (error) {
        console.error(error);
      }
    },
    generateX,
    gaussian,
    generateY,
    calculateMu,
    calculateSigma,
    thicknessMeasurementsUpdateChartData() {
      const mu = this.calculateMu(this.thickness_measurements);
      const sigma = this.calculateSigma(this.thickness_measurements, mu);

      this.$store.state.gaussianBenchmarkChart.labels = this.generateX(mu, sigma);
      this.$store.state.gaussianBenchmarkChart.datasets[0] = {
        label: "Initial Benchmark",
        data: this.generateY(sigma, mu),
      };

      if (
        this.benchmark_form.maximum_target_thickness !== null &&
        this.benchmark_form.minimum_target_thickness !== null
      ) {
        const arr = this.$store.state.gaussianBenchmarkChart.labels.map(num => parseFloat(num));
        let index = arr.findIndex(num => num > this.benchmark_form.minimum_target_thickness);
        let index_max = arr.findIndex(num => num > this.benchmark_form.maximum_target_thickness);

        this.$store.state.maxTargetThickness = index_max > 0 ? index_max - 1 : arr.length - 1;
        this.$store.state.minTargetThickness = index > 0 ? index - 1 : index;
      }
    },
  },
};
</script>
<style scoped>
.table input {
  width: 60px;
  text-align: center;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.container-fluid {
  padding-top: 20px;
}

.chart-canvas {
  max-height: 400px;
}
</style>

<i18n>
  {
    "EN": {
      "Benchmark & Target": "Benchmark & Target",
      "Save": "Save",
      "Changes saved": "Changes saved",
      "was saved successfully": "was saved successfully",
    },
    "DE": {
      "Benchmark & Target": "Benchmark & Ziel",
      "Save": "Speichern",
      "Changes saved": "Änderungen gespeichert",
      "was saved successfully": "wurde erfolgreich gespeichert",
    },
    "ES": {
      "Benchmark & Target": "Punto de referencia y objetivo",
      "Save": "Guardar",
      "Changes saved": "Cambios guardados",
      "was saved successfully": "se guardó correctamente",
    },
    "FR": {
      "Benchmark & Target": "Référence et objectif",
      "Save": "Sauvegarder",
      "Changes saved": "Modifications enregistrées",
      "was saved successfully": "a été enregistré avec succès",
    },
    "IT": {
      "Benchmark & Target": "Benchmark e obiettivo",
      "Save": "Salva",
      "Changes saved": "Modifiche salvate",
      "was saved successfully": "è stato salvato con successo",
    },
    "PL": {
      "Benchmark & Target": "Punkt odniesienia i cel",
      "Save": "Zapisz",
      "Changes saved": "Zmiany zapisane",
      "was saved successfully": "został zapisany pomyślnie",
    },
    "TR": {
      "Benchmark & Target": "Referans ve Hedef",
      "Save": "Kaydet",
      "Changes saved": "Değişiklikler kaydedildi",
      "was saved successfully": "başarıyla kaydedildi",
    },
    "ZH": {
      "Benchmark & Target": "基准和目标",
      "Save": "保存",
      "Changes saved": "更改已保存",
      "was saved successfully": "已成功保存",
    }
  }
</i18n>
